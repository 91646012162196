import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from "../inc/Header";
import Kanerdul from "../images/dul.jpg";
import Necklece from "../images/necklece.jpg";
import Breclet from "../images/bracelet.jpg";
import Ring from "../images/rings.jpg";
import Bala from "../images/bala.jpg";
import Chain from "../images/chain.jpg";
import Kanbala from "../images/kanbala.jpg";
import Bangles from "../images/bangles.jpg";
import Chikimage from "../images/chikimage.jpg";
import Locket from "../images/locket.jpg";
import Solidchuri from "../images/solidchurisss.jpg";
import Jhumka from "../images/jhumka.jpg";
import Bresletpola from "../images/pola.jpg";
import Belt_har from "../images/belthar.83f7170e.jpg";
import Sankha from "../images/sankha.jpg";
import Gold_Mantasa from "../images/mantasa.jpg";
import Babybalagold from "../images/babybala.jpg";
import Chur from "../images/chur.jpg";
import Stone from "../images/Probal.jpg";
import Astrologyberhampore from "../images/ajitsastri.jpg";

import "./Silck.css";

import { Link } from 'react-router-dom';


function Silck() {

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3
  };

  return (
    <div>
      <Header />

      <div className="animat">
        <Slider {...settings}>
          <div>
            <div className="card mb-4 border-White box">
              <Link to="/stone">
                <img src={Stone} className="card-img-top" alt="Stoneberhampore" />
              </Link>
              <div className="card-body">

                <p style={{ color: "maroon" }} className="card-text mb-0">Stone</p>
                <Link to="/stone">
                  <p className="card-text"> <small> <span>Explore<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                  </svg></span></small></p> </Link>
              </div>
            </div>
          </div>
          <div>
            <div className="card mb-4 border-White box ">
              <a href="Astro">
                <img src={Astrologyberhampore} className="card-img-top" alt="Astrologyberhampore" />
              </a>
              <div className="card-body">
            <div className='fonts'>
                <p style={{ color: "maroon" }} className="card-text mb-0"><small>Astrology</small></p>
                </div>
                <Link to="/Astro">
                  <p className="card-text mb-0"> <small> <span>Explore<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                  </svg></span></small></p> </Link>
              </div>
            </div>

          </div>
          <div>
            <div className="card mb-4 border-White box ">
              <Link to="/ring">
                <img src={Ring} className="card-img-top" alt="Ring" />
              </Link>
              <div className="card-body">

                <p style={{ color: "maroon" }} className="card-text mb-0">Ring</p>
                <Link to="/ring">
                  <p className="card-text mb-0"> <small> <span>Explore<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                  </svg></span></small></p> </Link>
              </div>
            </div>

          </div>



          <div>
            <div className="card mb-4 border-White box ">
              <Link to="/bala">
                <img src={Bala} className="card-img-top" alt="Bala" />
              </Link>
              <div className="card-body">

                <p style={{ color: "maroon" }} className="card-text mb-0">Bala</p>
                <Link to="/bala">
                  <p className="card-text"> <small> <span>Explore<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                  </svg></span></small></p> </Link>
              </div>
            </div>
          </div>

          <div>
            <div className="card mb-4 border-White box ">
              <Link to="/dul">
                <img src={Kanerdul} className="card-img-top" alt="Kanerdul" />
              </Link>
              <div className="card-body">

                <p style={{ color: "maroon" }} className="card-text mb-0">Dul</p>
                <Link to="/dul">
                  <p className="card-text"> <small> <span>Explore<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                  </svg></span></small></p> </Link>
              </div>
            </div>
          </div>

          <div>
            <div className="card mb-4 border-White box ">
              <Link to="/bracelet">
                <img src={Breclet} className="card-img-top" alt="mbdharandsonjewellers" />
              </Link>
              <div className="card-body">

                <p style={{ color: "maroon" }} className="card-text mb-0">Bracelet</p>
                <Link to="/bracelet">
                  <p className="card-text"> <small> <span>Explore<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                  </svg></span></small></p> </Link>
              </div>
            </div>
          </div>

          <div>
            <div className="card mb-4 border-White box ">
              <Link to="/necklace">
                <img src={Necklece} className="card-img-top" alt="Nacklece" />
              </Link>
              <div className="card-body">

                <p style={{ color: "maroon" }} className="card-text mb-0"><small>Necklace</small></p>
                <Link to="/necklace">
                  <p className="card-text"> <small> <span>Explore<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                  </svg></span></small></p> </Link>
              </div>
            </div>
          </div>


          {/* <div className="card mb-4 border-White box ">
            <Link to="/chik">
              <img src={Chikimage} className="card-img-top" alt="Chikimage" />
            </Link>
            <div className="card-body">

              <p style={{ color: "maroon" }} className="card-text mb-0">Chik</p>
              <Link to="/chik">
                <p className="card-text"> <small> <span>Explore<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                </svg></span></small></p> </Link>
            </div>
          </div> */}


          <div className="card mb-4 border-White box ">
            <Link to="/chain">
              <img src={Chain} className="card-img-top" alt="Chain" />
            </Link>
            <div className="card-body">

              <p style={{ color: "maroon" }} className="card-text mb-0">Chain</p>
              <Link to="/chain">
                <p className="card-text"> <small> <span>Explore<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                </svg></span></small></p> </Link>
            </div>
          </div>
          <div className="card mb-4 border-White box ">
            <Link to="/Locketpage">
              <img src={Locket} className="card-img-top" alt="Locket" />
            </Link>
            <div className="card-body">

              <p style={{ color: "maroon" }} className="card-text mb-0">Locket</p>
              <Link to="/Locketpage">
                <p className="card-text"> <small> <span>Explore<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                </svg></span></small></p> </Link>
            </div>
          </div>


          <div>
            <div className="card mb-4 border-White box ">
              <Link to="/kanbala">
                <img src={Kanbala} className="card-img-top" alt="Kanbala" />
              </Link>
              <div className="card-body">

                <p style={{ color: "maroon" }} className="card-text mb-0">Kan Bala</p>
                <Link to="/kanbala">
                  <p className="card-text"> <small> <span>Explore<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                  </svg></span></small></p> </Link>
              </div>
            </div>

          </div>
          <div className="card mb-4 border-White box ">
            <Link to="/solidchuri">
              <img src={Solidchuri} className="card-img-top" alt="solidchuri" />
            </Link>
            <div className="card-body">

              <p style={{ color: "maroon" }} className="card-text mb-0"> <small>Solid Churi</small></p>
              <Link to="/solidchuri">
                <p className="card-text"> <small> <span>Explore<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                </svg></span></small></p> </Link>
            </div>
          </div>
          <div className="card mb-4 border-White box ">
            <Link to="/jhumka">
              <img src={Jhumka} className="card-img-top" alt="Mbdharandsonjewellersjhumka" />
            </Link>
            <div className="card-body">

              <p style={{ color: "maroon" }} className="card-text mb-0"> <small>Jhumka</small></p>
              <Link to="/jhumka">
                <p className="card-text"> <small> <span>Explore<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                </svg></span></small></p> </Link>
            </div>
          </div>

          <div className="card mb-4 border-White box ">
            <Link to="/babybala">
              <img src={Babybalagold} className="card-img-top" alt="Mbdharandsonjewellers_baby_bala" />
            </Link>
            <div className="card-body">

              <p style={{ color: "maroon" }} className="card-text mb-0"> <small>Baby Bala</small></p>
              <Link to="/babybala">
                <p className="card-text"> <small> <span>Explore<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                </svg></span></small></p> </Link>
            </div>
          </div>
          <div className="card mb-4 border-White box ">
            <Link to="/belthar">
              <img src={Belt_har} className="card-img-top" alt="Mbdharandsonjewellers_Belt_har" />
            </Link>
            <div className="card-body">

              <p style={{ color: "maroon" }} className="card-text mb-0"> <small>Belt har</small></p>
              <Link to="/belthar">
                <p className="card-text"> <small> <span>Explore<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                </svg></span></small></p> </Link>
            </div>
          </div>
          <div className="card mb-4 border-White box ">
            <Link to="/sankha">
              <img src={Sankha} className="card-img-top" alt="Mbdharandsonjewellerssankha" />
            </Link>
            <div className="card-body">

              <p style={{ color: "maroon" }} className="card-text mb-0"> <small>Sankha</small></p>
              <Link to="/sankha">
                <p className="card-text"> <small> <span>Explore<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                </svg></span></small></p> </Link>
            </div>
          </div>
          <div className="card mb-4 border-White box">
            <Link to="/mantasa">
              <img src={Gold_Mantasa} className="card-img-top" alt="Mbdharandsonjewellers_mantasa" />
            </Link>
            <div className="card-body">

              <p style={{ color: "maroon" }} className="card-text mb-0"> <small>Mantasa</small></p>
              <Link to="/mantasa">
                <p className="card-text"> <small> <span>Explore<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                </svg></span></small></p> </Link>
            </div>
          </div>
          <div className="card mb-4 border-White box">
            <Link to="/chur">
              <img src={Chur} className="card-img-top" alt="Mbdharandsonjewellers_chur" />
            </Link>
            <div className="card-body">

              <p style={{ color: "maroon" }} className="card-text mb-0"> <small>Chur</small></p>
              <Link to="/chur">
                <p className="card-text"> <small> <span>Explore<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                </svg></span></small></p> </Link>
            </div>
          </div>
          <div className="card mb-4 border-White box ">
            <Link to="/bresletpola">
              <img src={Bresletpola} className="card-img-top" alt="Mbdharandsonjewellers_pola" />
            </Link>
            <div className="card-body">

              <p style={{ color: "maroon" }} className="card-text mb-0"> <small>Breslet Pola</small></p>
              <Link to="/bresletpola">
                <p className="card-text"> <small> <span>Explore<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                </svg></span></small></p> </Link>
            </div>
          </div>
          <div>
            <div className="card mb-4 border-White box ">
              <Link to="/Bangles">
                <img src={Bangles} className="card-img-top" alt="Bangles"/>
              </Link>
              <div className="card-body">

                <p style={{ color: "maroon" }} className="card-text mb-0">Bangles</p>
                <Link to="/Bangles">
                  <p className="card-text"> <small> <span>Explore<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                  </svg></span></small></p> </Link>
              </div>
            </div>


          </div>


        </Slider>
      </div>

    </div>
  )
}



export default Silck
