import React from "react";
import Header from '../inc/Header';
import { Link } from "react-router-dom";
import Babychain from "../images/babychain.jpg";
import Ladieschain from "../images/ladieschain.jpg";
import Gentschain from "../images/gentschain.jpg";
import ScrollToTop from "react-scroll-to-top";
import ReactWhatsapp from "react-whatsapp";

import {
    FacebookShareButton, WhatsappShareButton, WhatsappIcon,
    FacebookIcon, TwitterShareButton, EmailShareButton, EmailIcon,

    LinkedinShareButton,
    LinkedinIcon,
    TwitterIcon,
} from "react-share";
function Chainpage() {
    return (
        <>

            <Header />
            <br />
            <ScrollToTop smooth top="100" color="maroon" />

            <br />
            <br />
            <br />
           
        
              <div className='goldberhampore'>
                <div className="album py-1">
                  <div className="container mt-3">
                    <div className="row">
                      <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                          <li style={{ color: "maroon" }} className="breadcrumb-item"><Link style={{ color: "maroon" }} to="/" > <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-double-left" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M8.354 1.646a.5.5 0 0 1 0 .708L2.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                            <path fill-rule="evenodd" d="M12.354 1.646a.5.5 0 0 1 0 .708L6.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                          </svg> Home</Link></li>
        
                          <li className="breadcrumb-item active" aria-current="page">Chain pge</li>
        
                        </ol>
                      </nav>
                    </div>
        
                  </div>
                </div>
              </div>
        


            <div className="album py-2">

                <div className="container">

                    <div className="row">

                        <div className="col-md-4 mt-3">
                            <div className="card mb-4 border-White box shadow">
                                <Link to="/babychain">
                                    <img src={Babychain} className="card-img-top" alt="mbdharandsonjewellers_babychain" />
                                </Link>

                                <div className="card-body">

                                    <h6 className="card-text"> <b>Baby Chain</b></h6>
                                    <Link to="/babychain">
                                        <p className="card-text"> <small> <span>Explore<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                                        </svg></span></small></p> </Link>
                                    <p className="card-text">M.B Dhor and Son Jewellers</p>

                                    <button type="button" className="btn btn btn-dark me-2">
                                        Share <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-share-fill" viewBox="0 0 16 16">
                                            <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z" />
                                        </svg>
                                    </button>

                                    < FacebookShareButton url="https://www.mbdharandsonjewellers.com/babychain" className="me-2" quote={"Mb_dhar_and_son_jewellers"} hastag={"#mbdharandsonjewellers"}
                                    >
                                        <FacebookIcon size={37} round={true} />
                                    </FacebookShareButton>

                                    <  WhatsappShareButton url="https://www.mbdharandsonjewellers.com/babychain" className="me-2" quote={"Mb_dhar_and_son_jewellers"} hastag={"#mbdharandsonjewellers"}
                                    >
                                        <WhatsappIcon size={37} round={true} />
                                    </ WhatsappShareButton>

                                    < TwitterShareButton url="https://www.mbdharandsonjewellers.com/babychain" className="me-2" quote={"Mb_dhar_and_son_jewellers"} hastag={"#mbdharandsonjewellers"}
                                    >
                                        <TwitterIcon size={37} round={true} />
                                    </TwitterShareButton>
                                    <  LinkedinShareButton url="https://www.mbdharandsonjewellers.com/babychain" className="me-2" quote={"Mb_dhar_and_son_jewellers"} hastag={"#mbdharandsonjewellers"}
                                    >
                                        <  LinkedinIcon size={37} round={true} />
                                    </ LinkedinShareButton>

                                    <  EmailShareButton url="https://www.mbdharandsonjewellers.com/babychain" className="me-2" quote={"Mb_dhar_and_son_jewellers"} hastag={"#mbdharandsonjewellers"}
                                    >
                                        <  EmailIcon size={37} round={true} />
                                    </ EmailShareButton>
                                    <div className="d-flex justify-content-between align-items-center">

                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-md-4 mt-3">
                            <div className="card mb-4 border-white box shadow">
                                <Link to="/ladieschain" target="_blank" rel="noopener noreferrer">
                                    <img src={Ladieschain} className="card-img-top" alt="Mbdhorandsonjewellers_ladieschain" />
                                </Link>
                                <div className="card-body">

                                    <h6 className="card-text"><b>Ladies Chain</b></h6>
                                    <Link to="/ladieschain">
                                        <p className="card-text"> <small> <span>Explore<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                                        </svg></span></small></p> </Link>
                                    <p className="card-text"> M.B Dhor and Son Jewellers </p>
                                    <button type="button" className="btn btn btn-dark me-2">
                                        Share  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-share-fill" viewBox="0 0 16 16">
                                            <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z" />
                                        </svg>
                                    </button>
                                    < FacebookShareButton url="https://www.mbdharandsonjewellers.com/ladieschain" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#mbdharandsonjewellers"}
                                    >
                                        <FacebookIcon size={37} round={true} />
                                    </FacebookShareButton>

                                    <  WhatsappShareButton url="https://www.mbdharandsonjewellers.com/ladieschain" className="me-2" quote={"Mb_dhar_and_son_jewellers"} hastag={"#mbdharandsonjewellers"}
                                    >
                                        <WhatsappIcon size={37} round={true} />
                                    </ WhatsappShareButton>

                                    < TwitterShareButton url="https://www.mbdharandsonjewellers.com/ladieschain" className="me-2" quote={"Mb_dhar_and_son_jewellers"} hastag={"#mbdharandsonjewellers"}
                                    >
                                        <TwitterIcon size={37} round={true} />
                                    </TwitterShareButton>
                                    <  LinkedinShareButton url="https://www.mbdharandsonjewellers.com/ladieschain" className="me-2" quote={"Mb_dhar_and_son_jewellers"} hastag={"#mbdharandsonjewellers"}
                                    >
                                        <  LinkedinIcon size={37} round={true} />
                                    </ LinkedinShareButton>

                                    <  EmailShareButton url="https://www.mbdharandsonjewellers.com/ladieschain" className="me-2" quote={"Mb_dhar_and_son_jewellers"} hastag={"#mbdharandsonjewellers"}
                                    >
                                        <  EmailIcon size={37} round={true} />
                                    </ EmailShareButton>
                                    <div className="d-flex justify-content-between align-items-center">


                                    </div>
                                </div>
                            </div>

                        </div>
                        {/* style={{textAlign: "center"}} */}
                        <div className="col-md-4 mt-3">
                            <div className="card mb-4 border-white box shadow">
                               
                                    <img src={Gentschain} className="card-img-top" alt="mbdharandsonjewellers_mens_chain" />
                               
                                <div className="card-body">
                                    <h6 className="card-text"><b>Gents Chain</b></h6>
                                    <p className="card-text mb-2">M.B Dhor and Son Jewellers</p>

                                    <div className="d-flex justify-content-between align-items-center">

                                        <a style={{color:"white"}} href='tel: 9434168565 ' className="buttoncall mb-3">Call Book</a>
                                        <ReactWhatsapp number="+91 9434168565" className="buttonwhatsapp mb-3" message="I am Interested"> Whatsapp Inquiry</ReactWhatsapp>

                                    </div>

                                    <button type="button" className="btn btn btn-dark me-2">
                                        Share <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-share-fill" viewBox="0 0 16 16">
                                            <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z" />
                                        </svg>
                                    </button>

                                    < FacebookShareButton url="https://www.mbdharandsonjewellers.com/chain" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
                                    >
                                        <FacebookIcon size={37} round={true} />
                                    </FacebookShareButton>

                                    <  WhatsappShareButton url="https://www.mbdharandsonjewellers.com/chain" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
                                    >
                                        <WhatsappIcon size={37} round={true} />
                                    </ WhatsappShareButton>

                                    < TwitterShareButton url="https://www.mbdharandsonjewellers.com/chain" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
                                    >
                                        <TwitterIcon size={37} round={true} />
                                    </TwitterShareButton>
                                    <  LinkedinShareButton url="https://www.mbdharandsonjewellers.com/chain" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
                                    >
                                        <  LinkedinIcon size={37} round={true} />
                                    </ LinkedinShareButton>

                                    <  EmailShareButton url="https://www.mbdharandsonjewellers.com/chain" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
                                    >
                                        <  EmailIcon size={37} round={true} />
                                    </ EmailShareButton>
                                    <div className="d-flex justify-content-between align-items-center">




                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default Chainpage;