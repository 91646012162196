import React, { useState, useEffect } from 'react';
import { useNavigate} from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";
import PaginationOld from './PaginationOld';
import {db} from '../../firebase'
import Header from '../inc/Header';
import {Link} from "react-router-dom";
import ReactWhatsapp from "react-whatsapp";
import ScrollToTop from "react-scroll-to-top";
import { Typewriter } from 'react-simple-typewriter' 
import { 
  FacebookShareButton, WhatsappShareButton, WhatsappIcon,
   FacebookIcon, TwitterShareButton,   EmailShareButton,   EmailIcon,

   LinkedinShareButton,
   LinkedinIcon,
   TwitterIcon,
}  from "react-share";



function Locket () {
    const [ setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
      const [showPerPage, setShowPerPage] = useState(600);
      const [pagination, setPagination] =useState({
          start: 0,
          end: showPerPage,
      });
  
      const onPaginationChange = (start,  end) =>{
          setPagination({start: start, end: end});
      };
      const [searchTerm, setsearchTerm] = useState("");
      const [locket, setLocket] = useState([])
      const navigate = useNavigate()
  
      const locketCollectionRef = collection(db, "locket");
      useEffect(() => {
  
          const getLocket = async () => {
              const data = await getDocs(locketCollectionRef);
              setLocket(data.docs.map((doc) => ({...doc.data(), id: doc.id })))
          }
  
          getLocket()
      }, [])
    return (
      
<>
<Header/>

<br/>
<ScrollToTop smooth top="100" color="maroon" />

<br/>
<br/>
<br/>

      <div className='goldberhampore'>
        <div className="album py-1">
          <div className="container mt-3">
            <div className="row">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li style={{ color: "maroon" }} className="breadcrumb-item"><Link style={{ color: "maroon" }} to="/" > <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-double-left" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M8.354 1.646a.5.5 0 0 1 0 .708L2.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                    <path fill-rule="evenodd" d="M12.354 1.646a.5.5 0 0 1 0 .708L6.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                  </svg> Home</Link></li>

                  <li className="breadcrumb-item active" aria-current="page">Locket</li>

                </ol>
              </nav>
            </div>

          </div>
        </div>
      </div>
{/* <div className='goldberhampore'>
<div className="album py-1">
        
            <div className="container mt-3">

                <div className="row">
<nav aria-label="breadcrumb">
  <ol className="breadcrumb">
  <li style={{color:"maroon"}} className="breadcrumb-item"><Link style={{color:"maroon"}} to="/" > <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-double-left" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M8.354 1.646a.5.5 0 0 1 0 .708L2.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
  <path fill-rule="evenodd" d="M12.354 1.646a.5.5 0 0 1 0 .708L6.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
</svg> Back</Link></li>
    <li style={{color:"maroon"}} className="breadcrumb-item"><Link style={{color:"maroon"}} to="/chain" href="#">Chaina</Link></li>
    <li style={{color:"maroon"}} className="breadcrumb-item"><Link style={{color:"maroon"}} to="/bala" href="#">Bala</Link></li>
    <li style={{color:"maroon"}} className="breadcrumb-item"><Link style={{color:"maroon"}} to="/dul" href="#">Dul</Link></li>
    <li style={{color:"maroon"}} className="breadcrumb-item"><Link style={{color:"maroon"}} to="/necklace" href="#">Necklace</Link></li>
    <li style={{color:"maroon"}} className="breadcrumb-item"><Link style={{color:"maroon"}} to="/bracelet" href="#">Bracelet</Link></li>
    <li style={{color:"maroon"}} className="breadcrumb-item"><Link style={{color:"maroon"}} to="/solidchuri" href="#">Solid Churi</Link></li>
    <li style={{color:"maroon"}} className="breadcrumb-item"><Link style={{color:"maroon"}} to="/chik" href="#">Chik</Link></li>

    <li style={{color:"maroon"}} className="breadcrumb-item"><Link style={{color:"maroon"}} to="/kanbala" href="#">Kan Bala</Link></li>
    <li style={{color:"maroon"}} className="breadcrumb-item"><Link style={{color:"maroon"}} to="/ring" href="#">Ring</Link></li>
    <li style={{color:"maroon"}} className="breadcrumb-item"><Link style={{color:"maroon"}} to="/bawtry" href="#">Bawtry</Link></li>
    <li style={{color:"maroon"}} className="breadcrumb-item"><Link style={{color:"maroon"}} to="/contact" href="#">Contact</Link></li>
    <li className="breadcrumb-item active" aria-current="page">Locket</li>
    
  </ol>
</nav>
</div>
</div>
</div>
</div> */}

<h2 className="card-text mb-2" style={{paddingTop: "0rem", margin: "auto 0", color: "Gray", textAlign: "center"}}> 
<b> Search <span style={{color: "maroon", fontWeight: "bold", marginleft: '10px'}}>
    <Typewriter 
    loop
    cursor
    cursorStyle="_"
    typeSpeed={60}
    deleteSpeed={90}
    delaySpeed={1000}
    words={[ "Locket", "M. B. Dhar and Son Jewellers", "Best Jewellery Collections"]}
    />
</span>
</b></h2>
<div className="album py-2">
        
            <div className="container">

                <div className="row">
               
                <div className="col-md-6">
                        
                        <div className="card-body">
                        <input type="text" className="form-control" placeholder="Search" name="location" 
                        onChange ={(e) => {
                          setsearchTerm(e.target.value);
                        }}
                        />
                                <div className="d-flex justify-content-between align-items-center">
                                   
                                    
                            

                            </div>
                          
                    
                        </div>

                      
                    </div>
             
                    </div>
                  
                    </div>
                    </div>
<div className="album py-4">
        
        <div className="container">

            <div className="row">
            {locket.filter((val)=>{
      if (setsearchTerm === ""){
        return val;
      } else if (
        val.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        val.des.toLowerCase().includes(searchTerm.toLowerCase()) 
      
       
        
      ){
        return val;
      }
    }).slice(pagination.start, pagination.end).map((locket) => { return (
            <div className="col-md-4 mt-8" >
                        <div className="card mb-4 border-White box shadow">
                        <img  className="card-img-top" src={locket.Img}  alt="locket"/>
                            <div className="card-body">
                                
                                <h6 className="card-text"> <b>{locket.title}</b></h6>
                                < h5 style={{color: "Darkblue"}}className="card-text"> ₹{locket.price} </h5>
                                <p className="card-text mb-2">{locket.des}</p>

                                <div className="d-flex justify-content-between align-items-center">
                              
                              <a href='tel: 9434168565 ' className="buttoncall mb-3">Call Book</a>
                              <ReactWhatsapp number="+91 9434168565" className="buttonwhatsapp mb-3" message="I am Interested"> Whatsapp Inquiry</ReactWhatsapp>
                          
                           </div>
                                <button type="button" className="btn btn btn-dark me-1">
                                        Share  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-share-fill" viewBox="0 0 16 16">
  <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z"/>
</svg>
</button>  < FacebookShareButton url="https://www.mbdharandsonjewellers.com/locket" className="me-2" quote={"M B Dhar and Son Jewellers"} hastag={"#WebApp"}
>
    <FacebookIcon size={37} round={true}/>
</FacebookShareButton>

 <  WhatsappShareButton url="https://www.mbdharandsonjewellers.com/locket" className="me-2" quote={"M B Dhar and Son Jewellers"} hastag={"#WebApp"}
>
    <WhatsappIcon size={37} round={true}/>
</ WhatsappShareButton>

<  TwitterShareButton url="https://www.mbdharandsonjewellers.com/locket" className="me-2" quote={"M B Dhar and Son Jewellers"} hastag={"#WebApp"}
>
    <TwitterIcon size={37} round={true}/>
</ TwitterShareButton>

<  LinkedinShareButton url="https://www.mbdharandsonjewellers.com/locket" className="me-2" quote={"M B Dhar and Son Jewellers"} hastag={"#WebApp"}
>
    <  LinkedinIcon size={37} round={true}/>
</ LinkedinShareButton>

<  EmailShareButton url="https://www.mbdharandsonjewellers.com/locket" quote={"M B Dhar and Son Jewellers"} hastag={"#WebApp"}
>
 <  EmailIcon size={37} round={true}/>
</ EmailShareButton>
                                <div className="d-flex justify-content-between align-items-center">
                              
                               
                              
                            </div>
                            </div>
                            </div>
                               
                            </div>
                                )})} 
                            </div>
                            <PaginationOld showPerPage={showPerPage} 
                onPaginationChange={onPaginationChange}
                total={locket.length}
                />


                            </div>
                            </div>

</>
        
        );
    }
    
    export default Locket;



