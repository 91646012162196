import React from 'react'
import Header from "../inc/Header";
import Slider from "../inc/Slider";
import Neckless from "../images/neckless.jpg";
import hars from "../images/balamb.jpg";
import Bala from "../images/mala.jpg";
import modelling from "../images/modeller.jpg";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Harandneckless from "../images/har.jpg";
import Neck from "../images/necjlesshar.jpg";
import Necklessgreen from "../images/necklessgreen.jpg";
import Malagold from "../images/har1.jpg";
import { Link } from "react-router-dom";
import chur_gold from "../images/CHUR 65gm Weight.jpg";
import passa from "../images/PASA.jpg";
import ring from "../images/RING 2.jpg"
import watchdesign_gold from "../images/Watch Design New Post Ads _mbdhar_andson_jewellers.jpg";
import weddingcollection from "../images/Wedding_Collection gold.jpg";
import weddingcollectionnew from "../images/Wedding_collection.jpg";
import SolidRing from "../images/Solid Ring.jpg";
import Belthar from "../images/BELTHAR.jpg"
import Ladiesring from "../images/LADIES RING.jpg";
import locket from "../images/Locket New design.jpg";

import ScrollToTop from "react-scroll-to-top";
import FacebookEmbed from "./FacebookEmbed";
import Silck from "../pages/Silck";
import "./Home.css";

import SonarVideo from "../pages/SonarVideo";
import {
  FacebookShareButton, WhatsappShareButton, WhatsappIcon,
  FacebookIcon, TwitterShareButton, EmailShareButton, EmailIcon,

  LinkedinShareButton,
  LinkedinIcon,
  TwitterIcon,
} from "react-share";
import Locketpage from './Locketpage';
import Necklacehome from './Necklacehome';





function Home() {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <div>



      <Header />
      <br />
      <br />
      <br />

      <br />
      <ScrollToTop smooth top="100" color="maroon" />
      <div className="album py-1">
        <div className="container">
          <div className="row">
            <div className="col-md-8 mt-3">
              <Slider />
            </div>
            <div className="col-md-4 mt-3">
              <div className="card">
                <iframe className='mb-1' src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2Fmbdharandsonjewellers%2Fvideos%2F532916163085005%2F&show_text=false&width=560&t=0" height="212" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
                <iframe src="https://www.facebook.com/plugins/video.php?height=323&href=https%3A%2F%2Fwww.facebook.com%2Fmbdharandsonjewellers%2Fvideos%2F1534674610704432%2F&show_text=false&width=560&t=0" height="200" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>              </div>

            </div>
          </div>
        </div>
      </div>
      <div className="album py-2">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mt-3">
            <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fmbdharandsonjewellers&tabs=timeline&width=600&height=700&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId" width="100%" height="700"  scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>             
            </div>
            <div className="col-md-6 mt-3">
            <iframe src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Freel%2F618026067510441&width=500&show_text=false&height=889&appId" width="100%" height="700"  scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
            </div>
          </div>
        </div>
      </div>


      <Silck />
      <br />
      <br />
      <div className="album py-0">
        <div className="container">
          <div className='gold'>
            <h2><b>Latest</b> Products</h2>
          </div>
        </div>
      </div>
      <div className="album py-0">
        <div className="container">
          <div className="row">
            <div className="col-md-3 mt-3">
              <div className="card">
                <img src={chur_gold} className="card-img-top" alt="chur_gold" />
                <div className="card-body">
                  <div className='gold'>
                    <h5 className="card-title mb-1">Chur</h5>
                  </div>
                  <p className="card-text mb-2">Weight: 65gm</p>
                  <a style={{ color: "white" }} href="tel: 9434168565" className="callbutton"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone" viewBox="0 0 16 16">
                    <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.6 17.6 0 0 0 4.168 6.608 17.6 17.6 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.68.68 0 0 0-.58-.122l-2.19.547a1.75 1.75 0 0 1-1.657-.459L5.482 8.062a1.75 1.75 0 0 1-.46-1.657l.548-2.19a.68.68 0 0 0-.122-.58zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z" />
                  </svg> +91 9434168565</a>
                </div>
              </div>
            </div>
            <div className="col-md-3 mt-3">
              <div className="card">
                <img src={passa} className="card-img-top" alt="passa" />
                <div className="card-body">
                  <div className='gold'>
                    <h5 className="card-title mb-1">Pasaa</h5>
                  </div>
                  <p className="card-text mb-2">Weight: 3gm</p>
                  <a style={{ color: "white" }} href="tel: 9434168565" className="callbutton"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone" viewBox="0 0 16 16">
                    <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.6 17.6 0 0 0 4.168 6.608 17.6 17.6 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.68.68 0 0 0-.58-.122l-2.19.547a1.75 1.75 0 0 1-1.657-.459L5.482 8.062a1.75 1.75 0 0 1-.46-1.657l.548-2.19a.68.68 0 0 0-.122-.58zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z" />
                  </svg> +91 9434168565</a>
                </div>
              </div>
            </div>
            <div className="col-md-3 mt-3">
              <div className="card">
                <img src={ring} className="card-img-top" alt="ring" />
                <div className="card-body">
                  <div className='gold'>
                    <h5 className="card-title mb-1">Ring</h5>
                  </div>
                  <p className="card-text mb-2">Weight: 2.580gm</p>
                  <a style={{ color: "white" }} href="tel: 9434168565" className="callbutton"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone" viewBox="0 0 16 16">
                    <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.6 17.6 0 0 0 4.168 6.608 17.6 17.6 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.68.68 0 0 0-.58-.122l-2.19.547a1.75 1.75 0 0 1-1.657-.459L5.482 8.062a1.75 1.75 0 0 1-.46-1.657l.548-2.19a.68.68 0 0 0-.122-.58zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z" />
                  </svg> +91 9434168565</a>
                </div>
              </div>
            </div>
            <div className="col-md-3 mt-3">
              <div className="card">
                <img src={watchdesign_gold} className="card-img-top" alt="watchdesign_gold" />
                <div className="card-body">
                  <div className='gold'>
                    <h5 className="card-title mb-1">Watch Design</h5>
                  </div>
                  <p className="card-text mb-2">Best design watch</p>
                  <a style={{ color: "white" }} href="tel: 9434168565" className="callbutton"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone" viewBox="0 0 16 16">
                    <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.6 17.6 0 0 0 4.168 6.608 17.6 17.6 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.68.68 0 0 0-.58-.122l-2.19.547a1.75 1.75 0 0 1-1.657-.459L5.482 8.062a1.75 1.75 0 0 1-.46-1.657l.548-2.19a.68.68 0 0 0-.122-.58zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z" />
                  </svg> +91 9434168565</a>
                </div>
              </div>
            </div>

            <div className="col-md-4 mt-3">
              <div className="card">
                <img src={weddingcollection} className="card-img-top" alt="weddingcollectiongold" />
                <div className="card-body">
                  <div className='gold'>
                    <h5 className="card-title mb-1">Wedding Collection</h5>
                  </div>
                  <p className="card-text mb-2">Best Wedding Collection</p>
                  <a style={{ color: "white" }} href="tel: 9434168565" className="callbutton"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone" viewBox="0 0 16 16">
                    <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.6 17.6 0 0 0 4.168 6.608 17.6 17.6 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.68.68 0 0 0-.58-.122l-2.19.547a1.75 1.75 0 0 1-1.657-.459L5.482 8.062a1.75 1.75 0 0 1-.46-1.657l.548-2.19a.68.68 0 0 0-.122-.58zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z" />
                  </svg> +91 9434168565</a>
                </div>
              </div>
            </div>
            <div className="col-md-4 mt-3">
              <div className="card">
                <img src={weddingcollectionnew} className="card-img-top" alt="weddingcollectionnew" />
                <div className="card-body">
                  <div className='gold'>
                    <h5 className="card-title mb-1">Wedding Collection</h5>
                  </div>
                  <p className="card-text mb-2">Best Wedding Collection</p>
                  <a style={{ color: "white" }} href="tel: 9434168565" className="callbutton"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone" viewBox="0 0 16 16">
                    <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.6 17.6 0 0 0 4.168 6.608 17.6 17.6 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.68.68 0 0 0-.58-.122l-2.19.547a1.75 1.75 0 0 1-1.657-.459L5.482 8.062a1.75 1.75 0 0 1-.46-1.657l.548-2.19a.68.68 0 0 0-.122-.58zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z" />
                  </svg> +91 9434168565</a>
                </div>
              </div>
            </div>

            <div className="col-md-4 mt-3">
              <div className="card">
                <img src={SolidRing} className="card-img-top" alt="SolidRing" />
                <div className="card-body">
                  <div className='gold'>
                    <h5 className="card-title mb-1">Solid Ring</h5>
                  </div>
                  <p className="card-text mb-2">Best Solid Ring</p>
                  <a style={{ color: "white" }} href="tel: 9434168565" className="callbutton"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone" viewBox="0 0 16 16">
                    <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.6 17.6 0 0 0 4.168 6.608 17.6 17.6 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.68.68 0 0 0-.58-.122l-2.19.547a1.75 1.75 0 0 1-1.657-.459L5.482 8.062a1.75 1.75 0 0 1-.46-1.657l.548-2.19a.68.68 0 0 0-.122-.58zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z" />
                  </svg> +91 9434168565</a>
                </div>
              </div>

            </div>

            <div className="col-md-4 mt-3">
              <div className="card">
                <img src={Belthar} className="card-img-top" alt="Belthar" />
                <div className="card-body">
                  <div className='gold'>
                    <h5 className="card-title mb-1">Belt Har</h5>
                  </div>
                  <p className="card-text mb-2">Best Solid Ring</p>
                  <a style={{ color: "white" }} href="tel: 9434168565" className="callbutton"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone" viewBox="0 0 16 16">
                    <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.6 17.6 0 0 0 4.168 6.608 17.6 17.6 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.68.68 0 0 0-.58-.122l-2.19.547a1.75 1.75 0 0 1-1.657-.459L5.482 8.062a1.75 1.75 0 0 1-.46-1.657l.548-2.19a.68.68 0 0 0-.122-.58zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z" />
                  </svg> +91 9434168565</a>
                </div>
              </div>

            </div>

            <div className="col-md-4 mt-3">
              <div className="card">
                <img src={Ladiesring} className="card-img-top" alt="Ladiesring" />
                <div className="card-body">
                  <div className='gold'>
                    <h5 className="card-title mb-1">Solid Ring</h5>
                  </div>
                  <p className="card-text mb-2">Best Solid Ring</p>
                  <a style={{ color: "white" }} href="tel: 9434168565" className="callbutton"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone" viewBox="0 0 16 16">
                    <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.6 17.6 0 0 0 4.168 6.608 17.6 17.6 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.68.68 0 0 0-.58-.122l-2.19.547a1.75 1.75 0 0 1-1.657-.459L5.482 8.062a1.75 1.75 0 0 1-.46-1.657l.548-2.19a.68.68 0 0 0-.122-.58zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z" />
                  </svg> +91 9434168565</a>
                </div>
              </div>

            </div>




            <div className="col-md-4 mt-3">
              <div className="card">
                <img src={locket} className="card-img-top" alt="locket" />
                <div className="card-body">
                  <div className='gold'>
                    <h5 className="card-title mb-1">Locket</h5>
                  </div>
                  <p className="card-text mb-2">Best Locket</p>
                  <a style={{ color: "white" }} href="tel: 9434168565" className="callbutton"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone" viewBox="0 0 16 16">
                    <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.6 17.6 0 0 0 4.168 6.608 17.6 17.6 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.68.68 0 0 0-.58-.122l-2.19.547a1.75 1.75 0 0 1-1.657-.459L5.482 8.062a1.75 1.75 0 0 1-.46-1.657l.548-2.19a.68.68 0 0 0-.122-.58zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z" />
                  </svg> +91 9434168565</a>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>

      <br />      <div className="album py-2">

        <div className="container">

          <div className="row">
            <div className="mbdhar">
              <h1><b style={{ color: "maroon" }}>M. B. Dhar & Son Jewellers </b> </h1>
            </div>
         

          </div>
        </div>
      </div>

      <SonarVideo />

      <div className="album py-2">

        <div className="container">

          <div className="row">

            <div className="col-md-4 mt-3" >
              <div className="card mb-4 border-White box shadow">
                <img src={Neckless} className="card-img-top" alt="Neckless" />
                <div className="card-body">

                  <h6 className="card-text"> <b>Seli</b></h6>
                  <p className="card-text">M.B Dhor and Son Jewellers</p>

                  <button type="button" className="btn btn btn-dark me-2 ">
                    Share <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-share-fill" viewBox="0 0 16 16">
                      <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z" />
                    </svg>
                  </button>

                  < FacebookShareButton url="https://www.mbdharandsonjewellers.com/" className="me-2" quote={"MB_Dhar_and_Son_Jewellers"} hastag={"#WebApp"}
                  >
                    <FacebookIcon size={37} round={true} />
                  </FacebookShareButton>

                  <  WhatsappShareButton url="https://www.mbdharandsonjewellers.com/" className="me-2" quote={"MB_Dhar_and_Son_Jewellers"} hastag={"#WebApp"}
                  >
                    <WhatsappIcon size={37} round={true} />
                  </ WhatsappShareButton>

                  < TwitterShareButton url="https://www.mbdharandsonjewellers.com/" className="me-2" quote={"MB_Dhar_and_Son_Jewellers"} hastag={"#WebApp"}
                  >
                    <TwitterIcon size={37} round={true} />
                  </TwitterShareButton>
                  <  LinkedinShareButton url="https://www.mbdharandsonjewellers.com/" className="me-2" quote={"MB_Dhar_and_Son_Jewellers"} hastag={"#WebApp"}
                  >
                    <  LinkedinIcon size={37} round={true} />
                  </ LinkedinShareButton>

                  <  EmailShareButton url="https://www.mbdharandsonjewellers.com/" className="me-2" quote={"MB_Dhar_and_Son_Jewellers"} hastag={"#WebApp"}
                  >
                    <  EmailIcon size={37} round={true} />
                  </ EmailShareButton>
                  <div className="d-flex justify-content-between align-items-center">

                  </div>
                </div>
              </div>

            </div>
            <div className="col-md-4 mt-3">
              <div className="card mb-4 border-white box shadow">
                <Link to="/solidchuri" target="_blank" rel="noopener noreferrer">
                  <img src={hars} className="card-img-top" alt="Mbdhorandsonjewellers" />
                </Link>
                <div className="card-body">

                  <h6 className="card-text"><b>Solid Churi</b></h6>
                  <p className="card-text"> M.B Dhor and Son Jewellers </p>
                  <button type="button" className="btn btn btn-dark me-2">
                    Share  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-share-fill" viewBox="0 0 16 16">
                      <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z" />
                    </svg>
                  </button>
                  < FacebookShareButton url="https://www.mbdharandsonjewellers.com/" className="me-2" quote={"MB_Dhar_and_Son_Jewellers"} hastag={"#WebApp"}
                  >
                    <FacebookIcon size={37} round={true} />
                  </FacebookShareButton>

                  <  WhatsappShareButton url="https://www.mbdharandsonjewellers.com/" className="me-2" quote={"MB_Dhar_and_Son_Jewellers"} hastag={"#WebApp"}
                  >
                    <WhatsappIcon size={37} round={true} />
                  </ WhatsappShareButton>

                  < TwitterShareButton url="https://www.mbdharandsonjewellers.com/" className="me-2" quote={"MB_Dhar_and_Son_Jewellers"} hastag={"#WebApp"}
                  >
                    <TwitterIcon size={37} round={true} />
                  </TwitterShareButton>
                  <  LinkedinShareButton url="https://www.mbdharandsonjewellers.com/" className="me-2" quote={"MB_Dhar_and_Son_Jewellers"} hastag={"#WebApp"}
                  >
                    <  LinkedinIcon size={37} round={true} />
                  </ LinkedinShareButton>

                  <  EmailShareButton url="https://www.mbdharandsonjewellers.com/" className="me-2" quote={"MB_Dhar_and_Son_Jewellers"} hastag={"#WebApp"}
                  >
                    <  EmailIcon size={37} round={true} />
                  </ EmailShareButton>
                  <div className="d-flex justify-content-between align-items-center">


                  </div>
                </div>
              </div>

            </div>
            {/* style={{textAlign: "center"}} */}
            <div className="col-md-4 mt-3">
              <div className="card mb-4 border-white box shadow">
                <Link to="/chain" target="_blank" rel="noopener noreferrer" >
                  <img src={Bala} className="card-img-top" alt="Bala" />
                </Link>
                <div className="card-body">
                  <h6 className="card-text"><b>Chain</b></h6>
                  <p className="card-text">M.B Dhor and Son Jewellers</p>

                  <button type="button" className="btn btn btn-dark me-2">
                    Share <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-share-fill" viewBox="0 0 16 16">
                      <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z" />
                    </svg>
                  </button>

                  < FacebookShareButton url="https://www.mbdharandsonjewellers.com/" className="me-2" quote={"MB_Dhar_and_Son_Jewellers"} hastag={"#mbdharandsonjewellers"}
                  >
                    <FacebookIcon size={37} round={true} />
                  </FacebookShareButton>

                  <  WhatsappShareButton url="https://www.mbdharandsonjewellers.com/" className="me-2" quote={"MB_Dhar_and_Son_Jewellers"} hastag={"#WebApp"}
                  >
                    <WhatsappIcon size={37} round={true} />
                  </ WhatsappShareButton>

                  < TwitterShareButton url="https://www.mbdharandsonjewellers.com/" className="me-2" quote={"MB_Dhar_and_Son_Jewellers"} hastag={"#WebApp"}
                  >
                    <TwitterIcon size={37} round={true} />
                  </TwitterShareButton>
                  <  LinkedinShareButton url="https://www.mbdharandsonjewellers.com/" className="me-2" quote={"MB_Dhar_and_Son_Jewellers"} hastag={"#WebApp"}
                  >
                    <  LinkedinIcon size={37} round={true} />
                  </ LinkedinShareButton>

                  <  EmailShareButton url="https://www.mbdharandsonjewellers.com/" className="me-2" quote={"MB_Dhar_and_Son_Jewellers"} hastag={"#WebApp"}
                  >
                    <  EmailIcon size={37} round={true} />
                  </ EmailShareButton>
                  <div className="d-flex justify-content-between align-items-center">




                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>


      <marquee>
        <img src={modelling} className="img-fluid" alt="mbdhorandsonjewellermodel" /></marquee>


      <div className="containerse">
        <center>
          <div className="sentancestyle">
            <h1><b>M.B. DHAR AND SON JEWELLERS</b></h1><br />
          </div>
          <div className="sentancestyles">
            <h3>Best Jewellery Collections</h3>
          </div>
        </center>
      </div>

      {/* 
      <div className="appointmentbg">
        <div className="album py-4">

          <div className="container">

            <div className="row">

              <div className="col-md-4" >
                <button type="button" className="btn btn-outline-danger btn-sm " data-bs-toggle="modal" data-bs-target="#exampleModal">
                  Book Appointment
                </button>

                <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                  <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">M.B. DHAR AND SON JEWELLERS</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                      </div>
                      <div className="modal-body">
                        <AddAppointment />
                      </div>
                      <div className="modal-footer">

                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}


      <div className="album py-3">

        <div className="container">

          <div className="row">
            <h2 style={{ color: "maroon" }}>Best Collection </h2>
          </div>
        </div>
      </div>

      <div style={{ color: "white", padding: "14px;", margin: 10 }} className="fixed-bottom "> <a style={{ color: "white" }} href="tel: 9434168565" className="homebuttoncolor" > <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-phone" viewBox="0 0 16 16">
        <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z" />
        <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
      </svg> Call Us <div className="spinner-grow text-light spinner-grow-sm" role="status">
          <span className="visually-hidden"> Loading...</span>
        </div> </a></div>


      <div className="album py-2">
        <div className="container">
          <div className="row">

            <Carousel responsive={responsive}>
              <div className="card border mb-3 me-3" >
                <img className="card-img-top" src={Necklessgreen} alt="M_b_dhor_and_son_jewellers" />
                <div className="card-body text">
                  <center>
                    <h6 className="card-title mb-0"><b>Seli </b></h6>
                    <p className="card-text"><small>By M.B. Dhar and Son Jewellers </small></p>
                  </center>
                </div>
              </div>
              <div className="card border mb-3 me-3" >
                <img className="card-img-top" src={Neck} alt="M_b_dhor_and_son_jewellers" />
                <div className="card-body text">
                  <center>
                    <h6 className="card-title mb-0"><b>Necklace </b></h6>
                    <p className="card-text"><small>By M.B. Dhar and Son Jewellers</small></p>
                  </center>
                </div>
              </div>
              <div className="card border mb-3 me-3" >
                <img className="card-img-top" src={Malagold} alt="M_b_dhor_and_son_jewellers" />
                <div className="card-body text">
                  <center>
                    <h6 className="card-title mb-0"><b>Seli</b></h6>
                    <p className="card-text"><small>By M.B. Dhar and Son Jewellers</small></p>
                  </center>
                </div>
              </div>



              <div className="card border mb-3 me-3" >
                <img className="card-img-top" src={Harandneckless} alt="M_b_dhor_and_son_jewellers" />
                <div className="card-body text">
                  <center>
                    <h6 className="card-title mb-0"><b>Necklace </b></h6>
                    <p className="card-text"><small>By M.B. Dhar and Son Jewellers</small></p>
                  </center>
                </div>
              </div>
            </Carousel>
          </div>
        </div>

      </div>
      <br />
      <div className="album py-0">
        <div className="container">
          <div className='goldcolor'>
            <h1 className='mb-1' >Explore <b >Necklace</b></h1>
            <p>Trending necklace design available</p>
          </div>
        </div>
      </div>
      <Necklacehome />
      <div className="album py-0">
        <div className="container">
          <div className='goldcolor'>
            <h1 className='mb-1' >Explore <b >Locket</b></h1>
            <p>Trending locket design available</p>
          </div>
        </div>
      </div>
      <Locketpage />

      <br />
    </div>




  )
}

export default Home
