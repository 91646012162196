import React from 'react'
import Header from "../inc/Header";
import { Link } from "react-router-dom";
import happyramnavami from "../images/Ram_navami2025.jpg";
import {Helmet} from "react-helmet";

function Ramnabami2025() {
    return (
        <div>
            <Header />
            <br />
            <br />
            <br />
            <br />
            <Helmet>
                <meta charSet="utf-8" />
                <title>আগামী ৬ই এপ্রিল, ২০২৫ (২৩শে চৈত্র ১৪৩১) রবিবার "শুভ রামনবমী” উপলক্ষে আপনাদের জানাই সাদর আমন্ত্রণ। </title>
                <link rel="canonical" href="https://www.mbdharandsonjewellers.com/Ram_Navami_2025"/>
            </Helmet>
            <div className='ramnabami'>
                <div className="album py-2">
                    <div className="container mt-2">
                        <h3 className='mb-0' style={{ color: "white" }}>Happy Ram Navami</h3>
                        <hr className='mb-2' style={{ color: "white" }} />
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/" style={{ color: "white" }} >Home</Link></li>
                                <li style={{ color: "white" }} className="breadcrumb-item active" aria-current="page">Ram Navami 2025</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <div style={{ color: "white", padding: "14px;", margin: 10 }} className="fixed-bottom "> <a style={{ color: "white" }} href="tel: 9434168565" className="homebuttoncolor" > <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-phone" viewBox="0 0 16 16">
        <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z" />
        <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
      </svg> Call Us <div className="spinner-grow text-light spinner-grow-sm" role="status">
          <span className="visually-hidden"> Loading...</span>
        </div> </a></div>
            <div className="album py-3">
                <div className="container">
                    <div className='borderradius'>
                        <img src={happyramnavami} className="d-block w-100" alt="happyramnavami" />
                    </div>

                </div>
            </div>


        </div>
    )
}

export default Ramnabami2025