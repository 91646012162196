
import './App.css';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';


import Home from "./components/pages/Home";
import Contacts from "./components/pages/Contacts";
import Ring from "./components/pages/Ring";
import Dul from "./components/pages/Dul";
import Chain from "./components/pages/Chain";
import Chainpage from "./components/pages/Chainpage";
import Bala from "./components/pages/Bala";
import Konbala from "./components/pages/Konbala";
import Jhumka from "./components/pages/Jhumka";
import Babybala from "./components/pages/Babybala";
import Bresletpola from "./components/pages/Bresletpola";
import Ladieschain from "./components/pages/Ladieschain";
import Bawty from "./components/pages/Bawty";
// import Chik from "./components/pages/Chik";
import Bracelet from "./components/pages/Bracelet";
import Locket from "./components/pages/Locket";
import Solidchuri from "./components/pages/Solidchuri";
import Silck from "./components/pages/Silck";
import Footer from "./components/inc/Footer";
import Necklece from './components/pages/Necklece';

import Belthar from './components/pages/Belthar';
import Churpage from './components/pages/Churpage';

import Mantasa from './components/pages/Mantasa';
import Sankha from './components/pages/Sankha';
import Glasschur from './components/pages/Glasschur';
import Chur from './components/pages/Chur';
import Astro from './components/pages/Astro';
import Stone from './components/pages/Stone';

import Lockek2879 from './components/pages/Lockek2879';
import Lockethans from './components/pages/Lockethans';
import Lockethome from './components/pages/Lockethome';
import Necklacepage from './components/pages/Necklacepage';
import Career from './components/pages/Career';
import Passa from './components/pages/Passa';
import Terms from './components/pages/Terms';
import Privacy from './components/pages/Privacy';
import Ramnabami2025 from './components/pages/Ramnabami2025';







function App() {
  return (
    <Router>
    
    <div>

     
      
      <Routes>

      <Route axact path="/" element={<Home/>}/>
      <Route axact path="/contact" element={<Contacts/>}/>
        <Route axact path="/ring" element={<Ring/>}/>
        <Route axact path="/dul" element={<Dul/>}/>
        <Route axact path="/babychain" element={<Chain/>}/>
        <Route axact path="/chain" element={<Chainpage/>}/>
        <Route axact path="/bala" element={<Bala/>}/>
        <Route axact path="/jhumka" element={<Jhumka/>}/>
        <Route axact path="/babybala" element={<Babybala/>}/>
        <Route axact path="/churpage" element={<Churpage/>}/>
        <Route axact path="/bresletpola" element={<Bresletpola/>}/>
        <Route axact path="/ladieschain" element={<Ladieschain/>}/>
        <Route axact path="/kanbala" element={<Konbala/>}/>
        <Route axact path="/bracelet" element={<Bracelet/>}/>
        <Route axact path="/necklace" element={<Necklece/>}/>
            <Route axact path="/Bangles" element={<Bawty/>}/>
        
            <Route axact path="/locket" element={<Locket/>}/>
            <Route axact path="/solidchuri" element={<Solidchuri/>}/>
            <Route axact path="/belthar" element={<Belthar/>}/>
            <Route axact path="/sankha" element={<Sankha/>}/>
            <Route axact path="/mantasa" element={<Mantasa/>}/>
            <Route axact path="/glasschur" element={<Glasschur/>}/>
            <Route axact path="/chur" element={<Chur/>}/>
            <Route axact path="/Astro" element={<Astro/>}/>
            <Route axact path="/stone" element={<Stone/>}/>
        <Route axact path="/silck" element={<Silck/>}/>
        <Route axact path="/Locketpage" element={<Lockethome/>}/>
        <Route axact path="/Lockek2879" element={<Lockek2879/>}/>
        <Route axact path="/Locket3.200" element={<Lockethans/>}/>
        <Route axact path="/necklace-page" element={<Necklacepage/>}/>
        <Route axact path="/career" element={<Career/>}/>
        <Route axact path="/passa" element={<Passa/>}/>  
          <Route axact path="/terms" element={<Terms/>}/>

          <Route axact path="/privacy" element={<Privacy/>}/>
          <Route axact path="/Ram_Navami_2025" element={<Ramnabami2025/>}/>

      </Routes>
    <Footer/>
  
    </div>

    </Router>
  );
}

export default App;
