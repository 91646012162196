import React from 'react'
import Bestastrologyberhampore from "../images/bestastroberhampore.jpg";
import Header from "../inc/Header"
import {Link} from "react-router-dom";

function Astro() {
    return (
        <div>
            <Header />
            <br />
            <br />
            <br />
            <br />
          

          <div className='goldberhampore'>
                          <div className='album py-1'>
                              <div className="container mt-3">
                                  <nav aria-label="breadcrumb">
                                      <ol className="breadcrumb">
                                          <li className="breadcrumb-item"><Link style={{ color: "black" }} to="/" href="#">Home</Link></li>
                                          <li className="breadcrumb-item active" aria-current="page">Astrology <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                                              <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708" />
                                          </svg></li>
                                      </ol>
                                  </nav>
                              </div>
                          </div>
                      </div>
                      <br/>
            <div className="album py-0">
                <div className="container">
                  
                        <div className="card mb-3" >
                            <div className="row g-0">
                                <div className="col-md-5">
                                    <img src={Bestastrologyberhampore} className="img-fluid rounded-start" alt="Bestastrologyberhampore" />
                                </div>
                                <div className="col-md-7">
                                    <div className="card-body">
                                        <h4 className="card-title mb-1"><b>Shree Ajit Sastri <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="currentColor" className="bi bi-check2-circle" viewBox="0 0 16 16">
  <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0"/>
  <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0z"/>
</svg></b></h4>
                                        <h6 className="card-text mb-5">Gold Medalist</h6>
                                        <h5 className="card-text"><small className="text-muted"> জ্যোতিষ বিভাগ </small></h5>
                                        <h6 style={{color:"maroon"}} className="card-text mb-4 "> প্রতি শুক্রবার দুফুর ১২ টা থেকে সন্ধে ৬ টা </h6>
                                        <a style={{color:"white"}} className='buttonastro' href='tel: +91 9046209114'>Call Us Now</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
              
            </div>
            <br />
            <br />
        </div>
    )
}

export default Astro