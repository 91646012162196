import React from 'react'
import Slide from "../images/slide1.jpg";
import SlideS from "../images/MBSLIDE.jpg";
import Main from "../images/MAINADS.jpg";
import mbdharastro from "../images/mb_astrology.jpg";
import Eid from "../images/Eid_MaheRamjan.jpg";
import ramnavami from "../images/Ramnavami_mb dhar.jpg";

function Slider() {
  return (
    <div>
    <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
  <div className="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="4" aria-label="Slide 5"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="4" aria-label="Slide 5"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="5" aria-label="Slide 6"></button>


  </div>
  <div className="carousel-inner">
  <div className="carousel-item active">
      <div className='border-radius'>
      <img src={ramnavami} className="d-block w-100" alt="ramnavami"/>
      </div>
    </div>
    <div className="carousel-item ">
      <div className='border-radius'>
      <img src={Eid} className="d-block w-100" alt="MB-DHAR-AND-JEWELLERS"/>
      </div>
    </div>
     <div className="carousel-item ">
      <div className='border-radius'>
      <img src={Main} className="d-block w-100" alt="MB-DHAR-AND-JEWELLERS"/>
      </div>
    </div>
    <div className="carousel-item">
    <div className='border-radius'>
      <img src={mbdharastro} className="d-block w-100" alt="MB-DHAR-AND-JEWELLERS"/>
      </div>
    </div>
    <div className="carousel-item">
    <div className='border-radius'>
      <img src={Slide} className="d-block w-100" alt="MB-DHAR-AND-JEWELLERS"/>
      </div>
    </div>
    <div className="carousel-item">
    <div className='border-radius'>
      <img src={SlideS} className="d-block w-100" alt="MB-DHAR-AND-JEWELLERS"/>
      </div>
    </div>
   
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
</div>
    </div>
  )
}

export default Slider
